import { chakra, forwardRef, type HTMLChakraProps } from '@chakra-ui/react';
import { hash } from '@ikona/hash';
import type { IconName } from '@ikona/types/icon-name';
import type { Ref, SVGProps } from 'react';
export type { IconName };

export const SvgEdit = forwardRef(
   (
      {
         name,
         ...props
      }: Omit<SVGProps<SVGSVGElement>, 'name'> & { name: IconName },
      ref: Ref<SVGSVGElement>,
   ) => (
      <svg ref={ref} {...props}>
         <use href={`/icons/sprite.${hash}.svg#${name}`} />
      </svg>
   ),
);

export const Icon = chakra<
   typeof SvgEdit,
   HTMLChakraProps<'svg'> & { name: IconName }
>(SvgEdit);
